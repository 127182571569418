import "./App.css";
import { useEffect, useState } from "react";

function MainPage() {
  // const api = "http://127.0.0.1:3000";
  const api = "/api";

  const [showVideo, setShowVideo] = useState(false);
  const [video, setVideo] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const tmpApi = api + "/data";
      try {
        const response = await fetch(tmpApi);
        const data = await response.json();
        // console.log("Data fetched:", data);
        setShowVideo(data.showVideo);
        if (data.showVideo) {
          if (data.video !== video) {
            setVideo(data.video);
            setTitle(data.title);
          }
        } else {
          setVideo("");
          setTitle("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const intervalId = setInterval(fetchData, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClick = async (dataSceen) => {
    // console.log(`Image clicked: ${dataSceen}`);
    const data = { screen: dataSceen };
    const tmpApi = api + "/screen";
    try {
      const response = await fetch(tmpApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      alert(`Data transferred, please wait for the broadcast update`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!showVideo) {
    return (
      <div className="App">
        <div className="logo_1">
          <img width={320} src="logo_sp.png" alt="Logo" />
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      <p>{title}</p>
      <div>
        <img
          src="1-2.png"
          alt="1-2"
          className="imgC"
          onClick={() => handleClick("1-2")}
        />
        <img
          src="1.png"
          alt="1"
          className="imgC"
          onClick={() => handleClick("1")}
        />
        <img
          src="1+2.png"
          alt="1+2"
          className="imgC"
          onClick={() => handleClick("1+2")}
        />
        <img
          src="2.png"
          alt="2"
          className="imgC"
          onClick={() => handleClick("2")}
        />
        <img
          src="2+1.png"
          alt="2+1"
          className="imgC"
          onClick={() => handleClick("2+1")}
        />
      </div>
      <div className="app_video">
        <div className="video-container">
          <iframe
            src={video}
            className="iframe"
            allow="autoplay; fullscreen"
            allowFullScreen
            title={title}
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
