import "./App.css";
import { useState } from "react";

function SettingsPage() {
  // const api = "http://127.0.0.1:3000/data";
  const api = "/api/data";
  // const [video, setVideo] = useState(
  //   "http://95.67.69.226:8081/4d1c6146-ebcb-4e9d-9389-769b7e9a361f.html"
  // );
  const [video, setVideo] = useState(
    "https://restreamer.space-production.info/4d1c6146-ebcb-4e9d-9389-769b7e9a361f.html"
  );
  const [title, setTitle] = useState("Stream FilmVO");

  const saveAndSend = async () => {
    const data = {
      showVideo: true,
      video: video,
      title: title,
    };
    console.log(data);
    try {
      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const off = async () => {
    const data = {
      showVideo: false,
      video: "",
      title: "",
    };
    try {
      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="App">
      <div className="logo_1">
        <label>Video : </label>
        <input
          type="text"
          value={video}
          onChange={(e) => setVideo(e.target.value)}
        ></input>
        <br></br>

        <label>Title: </label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        ></input>
        <br></br>

        <br></br>
        <button
          onClick={() => {
            saveAndSend();
          }}
        >
          Save and send ON
        </button>
        <br></br>
        <br></br>
        <button
          onClick={() => {
            off();
          }}
        >
          OFF
        </button>
      </div>
    </div>
  );
}

export default SettingsPage;
